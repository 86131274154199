<template>
  <BalanceTopUp
    :modal-balance="false"
    :amount="1"
    v-if="isBalanceTopUp"
    @onClose="closeAddCardModal()"
  />
</template>
<script setup>
import { ref, onMounted } from 'vue'
import BalanceTopUp from '@/components/companyControlsCenter/components/Balance/BalanceTopUpModal.vue';

const isBalanceTopUp = ref(false);
const emits = defineEmits(['onClose'])

const closeAddCardModal = () => {
  isBalanceTopUp.value = false
  emits('onClose')
};

onMounted(() => {
  isBalanceTopUp.value = true;
});
</script>
