<template>
  <div
    v-loading="loading"
    class="bg-white fixed w-full h-max-content z-10 rounded-xl max-w-25 invite-container"
  >
    <ModalWrapper
      :title="$t('settings.invite.title')"
      :title-classes="['text-black', 'font-bold']"
      :header-classes="['divider']"
      :footer-classes="['divider-before']"
      :submit-text="$t('settings.invite.submit_button')"
      :is-disabled="isSubmitDisabled"
      title-size="24"
      is-cancel
      @onClose="$emit('onClose')"
      @onSubmit="inviteUser"
    >
      <el-form
        :model="data"
        :ref="refs.inviteUserValidation"
        class="flex flex-col gap-4"
      >
        <div class="relative">
          <span class="input-label">
            {{ $t('settings.invite.user_email') }}
          </span>
          <el-form-item :rules="rules.email" prop="email">
            <el-input
              v-model="data.email"
              :placeholder="$t('settings.invite.user_email_placeholder')"
              autocomplete="off"
            />
          </el-form-item>
        </div>
        <div class="relative">
          <span class="input-label">
            {{ $t('settings.invite.access_level') }}
          </span>
          <el-form-item :rules="rules.role" prop="role">
            <el-select v-model="data.role" class="w-full">
              <el-option
                v-for="role in roles"
                :key="role.key"
                :label="role.value"
                :value="role"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </ModalWrapper>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed } from 'vue'
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import { refs } from '@/components/companyControlsCenter/helpers/index.js'

import ModalWrapper from '@/components/companyControlsCenter/components/ControlsModalWrapper.vue'

const { useGetters, useActions } = createVuexHelpers()

const { roles } = useGetters('properties', ['roles'])

const { INVITE_USER } = useActions('users', ['INVITE_USER'])

const emits = defineEmits(['onClose'])

const $t = useI18n()
const instance = getCurrentInstance()

const data = ref({})

const loading = ref(false)

const rules = ref({
  email: [
    {
      required: true,
      message: $t('settings.invite.errors.user_email')
    }
  ],
  role: [
    {
      required: true,
      message: $t('settings.invite.errors.access_level')
    }
  ]
})

const isSubmitDisabled = computed(() => {
  return false
})

const inviteUser = async () => {
  console.log('INVITE')
  await refs.inviteUserValidation.value.validate(async (value) => {
    if (!value) return

    try {
      loading.value = true

      await INVITE_USER(data.value)

      instance.proxy.$showSuccess($t('settings.invite.success.invite'))
      emits('onClose')
    } catch (err) {
      instance.proxy.$showError(err)
    } finally {
      loading.value = false
    }
  })
}
</script>

<style lang="scss">
@import '@/components/companyControlsCenter/assets/styles/InviteUser.scss';
</style>
