<template>
  <div
    v-loading="loading"
    class="bg-white absolute w-full h-max-content z-10 top-0 right-0 rounded-xl user-info-container"
  >
    <ModalWrapper
      :title="$t('settings.user.edit')"
      :header-classes="['divider']"
      :footer-classes="['divider-before']"
      :submit-text="$t('save')"
      show-history
      is-cancel
      @onClose="$emit('onClose')"
      @onSubmit="saveUserInfo"
      @onShowHistory="$emit('onShowHistory')"
    >
      <template #subtitle>
        <p class="text-grey-200">
          {{ $t('settings.user.user') }} {{ user.name }}
        </p>
        <p class="text-grey-200">
          {{ $t('settings.user.access_level') }} {{ user.role.value }}
          <!-- HIDE FOR NOW UNIL FUNCTIONAL IN DEVELOPING -->
          <!-- <span class="text-blue-200 cursor-pointer">{{ $t('change') }}</span> -->
          <!-- HIDE FOR NOW UNIL FUNCTIONAL IN DEVELOPING -->
        </p>
      </template>
      <AvatarColors
        v-loading="isAvatarLoading"
        class="mb-4"
        :selected-color="selectedColor"
        :photos="data.photos"
        @setColor="setColor"
        @uploadAvatar="uploadAvatarHandler"
      />
      <el-form
        :model="data"
        :ref="refs.userSettingsValidation"
        class="flex flex-col gap-4"
      >
        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.user.name') }}
          </span>
          <el-form-item :rules="rules.name" prop="name">
            <el-input v-model="data.name" autocomplete="off" />
          </el-form-item>
        </div>

        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.user.email') }}
          </span>
          <el-form-item :rules="rules.email" prop="email">
            <el-input v-model="data.email" autocomplete="off" />
          </el-form-item>
        </div>
        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('role') }}
          </span>
          <el-form-item prop="role.key">
            <el-select
              v-if="allowedRoles.length"
              v-model="data.role.key"
              :placeholder="$t('select')"
              class="w-full"
            >
              <el-option
                v-for="item in allowedRoles"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
            <el-select
              v-else
              v-model="data.role.value"
              :placeholder="$t('select')"
            />
          </el-form-item>
        </div>
        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.user.language') }}
          </span>
          <el-select v-model="data.lang.key" filterable class="w-full">
            <el-option
              v-for="lang in languages"
              :key="lang.key"
              :label="lang.value"
              :value="lang.key"
            />
          </el-select>
        </div>
        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.user.phone') }}
          </span>
          <el-input v-model="data.phone" autocomplete="off" />
        </div>
        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.user.code') }}
          </span>
          <el-input v-model="data.code" type="number" autocomplete="off" />
        </div>

        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.user.mailing_id') }}
          </span>
          <el-input
            v-model="data.telegram_chat_id"
            type="number"
            autocomplete="off"
          />
        </div>
        <div
          class="flex items-center gap-3 bg-blue-100 text-blue-400 text-xs font-normal p-3 rounded-xl"
        >
          <NoteInfoIcon />
          <div class="break-normal text-left leading-3.5">
            <p>{{ $t('settings.user.note.first') }}</p>
            <p>{{ $t('settings.user.note.second') }}</p>
          </div>
        </div>
        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.user.description') }}
          </span>
          <el-input
            v-model="data.details"
            type="textarea"
            rows="3"
            ref="detailsRef"
          />
        </div>
        <div class="text-sm text-blue-200 text-center">
          <p class="mb-3 cursor-pointer" @click="openSetPasswordModal">
            {{ $t('settings.user.change_password') }}
          </p>

          <!-- HIDE FOR NOW UNIL FUNCTIONAL IN DEVELOPING -->
          <!-- <p class="cursor-pointer">
            {{ $t('settings.user.change_access_level') }}
          </p> -->
          <!-- HIDE FOR NOW UNIL FUNCTIONAL IN DEVELOPING -->
        </div>
      </el-form>
      <Overlay
        :visible="isChangePassword"
        class="rounded-xl"
        @click.native="isChangePassword = false"
      />
      <ChangePassword
        v-if="isChangePassword"
        @onClose="isChangePassword = false"
      />
    </ModalWrapper>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  ref,
  getCurrentInstance,
  onBeforeUnmount,
  computed,
  onMounted
} from 'vue'

import NoteInfoIcon from '@/components/companyControlsCenter/assets/icons/note-info.vue'

import ModalWrapper from '@/components/companyControlsCenter/components/ControlsModalWrapper.vue'
import Overlay from '@/components/companyControlsCenter/components/ControlsOverlay.vue'
import AvatarColors from '@/components/companyControlsCenter/components/UserInfo/AvatarColors.vue'
import ChangePassword from '@/components/companyControlsCenter/components/UserInfo/ChangePassword.vue'

import ElementLocale from 'element-ui/lib/locale'
import localeEn from 'element-ui/lib/locale/lang/en.js'
import localeRu from 'element-ui/lib/locale/lang/ru-RU.js'
import localeKz from 'element-ui/lib/locale/lang/kz.js'

import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'

import { refs } from '@/components/companyControlsCenter/helpers/index.js'

const emits = defineEmits(['onClose'])

const { useGetters, useActions } = createVuexHelpers()

const { user } = useGetters('login', ['user'])
const { uploadAvatar } = useActions('login', ['uploadAvatar'])

const { languages, roles } = useGetters('properties', ['languages', 'roles'])

const { UPDATE_MYSELF } = useActions('users', ['UPDATE_MYSELF'])

const $t = useI18n()
const instance = getCurrentInstance()

const data = ref({})

const selectedColor = ref('')

const isChangePassword = ref(false)

const loading = ref(false)
const isAvatarLoading = ref(false)

const rules = ref({
  name: [
    {
      required: true,
      message: $t('settings.user.errors.name')
    }
  ],
  email: [
    {
      required: true,
      message: $t('settings.user.errors.email')
    }
  ]
})

const detailsRef = ref(null)

const allowedRoles = computed(() => {
  const idx = roles.value.findIndex((item) => user.value.role.key === item.key)

  return roles.value.slice().splice(0, idx + 1)
})

const setColor = (color) => {
  data.value.properties.color = selectedColor.value = color
}

const getColor = () => {
  if (data.value.properties) {
    selectedColor.value = data.value.properties.color
      ? data.value.properties.color
      : ''
  } else {
    data.value.properties = { color: '' }
  }
}

const uploadAvatarHandler = async ({ target }) => {
  try {
    isAvatarLoading.value = true
    const body = new FormData()
    body.append('file', target.files[0], target.files[0].name)

    const photos = await uploadAvatar(body)

    data.value.photos = photos

    instance.proxy.$showSuccess($t('settings.user.success.avatar_change'))
  } catch (err) {
    instance.proxy.$showError(err)
  } finally {
    isAvatarLoading.value = false
  }
}

const saveUserInfo = async () => {
  console.log('HERE')
  await refs.userSettingsValidation.value.validate(async (value) => {
    if (!value) return

    try {
      loading.value = true

      await UPDATE_MYSELF(data.value)
      let locale
      // const locale = user.value.lang.key === 'ru' ? localeRu : localeEn
      if (user.value.lang.key === 'ru') {
        locale = localeRu
      } else if (user.value.lang.key === 'en') {
        locale = localeEn
      } else {
        locale = localeKz
      }
      ElementLocale.use(locale)
      instance.proxy.$i18n.set(user.value.lang.key)
      const langHtml = window.document.querySelector('html')
      langHtml.setAttribute('lang', user.value.lang.key)

      instance.proxy.$showSuccess($t('settings.user.success.update_info'))

      emits('onClose')
    } catch (err) {
      console.log(err)
    } finally {
      loading.value = false
    }
  })
}

const openSetPasswordModal = () => {
  isChangePassword.value = true
}

onBeforeMount(() => {
  if (user.value) data.value = JSON.parse(JSON.stringify(user.value))

  getColor()
})

onBeforeUnmount(() => {
  isChangePassword.value = false
})
</script>

<style lang="scss">
@import '@/components/companyControlsCenter/assets/styles/UserInfoModal.scss';
</style>
