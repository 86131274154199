<template>
  <ModalWrapper
    :title="$t('settings.user.change_password')"
    :submit-text="$t('save')"
    :loading="loading"
    class="bg-white absolute w-full set-password-modal"
    @onClose="$emit('onClose')"
    @onSubmit="changePasswordHandler"
  >
    <el-form
      :model="data"
      :ref="refs.userChangePasswordValidation"
      class="flex flex-col gap-4"
    >
      <el-form-item
        :rules="rules.oldPassword"
        prop="oldPassword"
        class="relative"
      >
        <span class="input-label">
          {{ $t('loginPass.changePass.old_pass') }}
        </span>
        <el-input
          v-model="data.oldPassword"
          autocomplete="off"
          :type="isOldPasswordVisible ? 'text' : 'password'"
        >
          <template slot="suffix">
            <PassEyeDisabled v-if="!data.oldPassword" class="mr-3" />
            <PassEye
              v-else-if="!isOldPasswordVisible"
              class="mr-3 cursor-pointer"
              @click.native="isOldPasswordVisible = !isOldPasswordVisible"
            />
            <PassEyeHide
              v-else
              class="mr-3 cursor-pointer"
              @click.native="isOldPasswordVisible = !isOldPasswordVisible"
            />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        :rules="rules.newPassword"
        prop="newPassword"
        class="relative"
      >
        <span class="input-label">
          {{ $t('loginPass.changePass.newPass') }}
        </span>
        <el-input
          v-model="data.newPassword"
          autocomplete="off"
          newPasswordConfirm
          :type="isNewPasswordVisible ? 'text' : 'password'"
        >
          <template slot="suffix">
            <PassEyeDisabled v-if="!data.newPassword" class="mr-3" />
            <PassEye
              v-else-if="!isNewPasswordVisible"
              class="mr-3 cursor-pointer"
              @click.native="isNewPasswordVisible = !isNewPasswordVisible"
            />
            <PassEyeHide
              v-else
              class="mr-3 cursor-pointer"
              @click.native="isNewPasswordVisible = !isNewPasswordVisible"
            />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        :rules="rules.newPasswordConfirm"
        :class="{ 'mt-8': isPasswordError }"
        class="relative"
        prop="newPasswordConfirm"
      >
        <span class="input-label">
          {{ $t('loginPass.changePass.newPass_confirm') }}
        </span>
        <el-input
          v-model="data.newPasswordConfirm"
          autocomplete="off"
          :type="isNewPasswordConfirmVisible ? 'text' : 'password'"
        >
          <template slot="suffix">
            <PassEyeDisabled v-if="!data.newPasswordConfirm" class="mr-3" />
            <PassEye
              v-else-if="!isNewPasswordConfirmVisible"
              class="mr-3 cursor-pointer"
              @click.native="
                isNewPasswordConfirmVisible = !isNewPasswordConfirmVisible
              "
            />
            <PassEyeHide
              v-else
              class="mr-3 cursor-pointer"
              @click.native="
                isNewPasswordConfirmVisible = !isNewPasswordConfirmVisible
              "
            />
          </template>
        </el-input>
      </el-form-item>
    </el-form>
  </ModalWrapper>
</template>
<script setup>
import { ref, getCurrentInstance, computed } from 'vue'

import { createVuexHelpers } from 'vue2-helpers'
import { refs } from '@/components/companyControlsCenter/helpers/index.js'
import { useI18n } from '@/hooks/useI18n'

import PassEye from '@/components/companyControlsCenter/assets/icons/pass-eye.vue'
import PassEyeHide from '@/components/companyControlsCenter/assets/icons/pass-eye-hide.vue'
import PassEyeDisabled from '@/components/companyControlsCenter/assets/icons/pass-eye-disabled.vue'
import ModalWrapper from '@/components/companyControlsCenter/components/ControlsModalWrapper.vue'

const emits = defineEmits(['onClose'])

const $t = useI18n()
const instance = getCurrentInstance()

const { useGetters, useActions } = createVuexHelpers()

const { activeCompany, roles } = useGetters('login', ['activeCompany', 'roles'])

const { changePassword } = useActions('login', ['changePassword'])

const data = ref({
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: ''
})

const isOldPasswordVisible = ref(false)
const isNewPasswordVisible = ref(false)
const isNewPasswordConfirmVisible = ref(false)
const isPasswordError = ref(false)

const adminOrHigherStrongPassCheck =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[~!@#$%^&*()\-+=\[\]{}|;:'",<.>/?]).{15,25}$/
const simpleUserStrongPassCheck =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[~!@#$%^&*()\-+=\[\]{}|;:'",<.>/?]).{8,25}$/
const simplePassCheck = /^(?=.*[a-zA-Z])(?=.*\d).{5,25}$/

const isAdminOrHigher = computed(() => {
  return (
    roles.value.key === 'ADMIN' ||
    roles.value.key === 'SUPERVISOR' ||
    roles.value.key === 'TARIFF_EDITOR' ||
    roles.value.key === 'CONTROLLER'
  )
})

const loading = ref(false)

const validatePassword = (rule, value, callback) => {
  if (activeCompany.value.strong_password) {
    let regexp = adminOrHigherStrongPassCheck
    let errText = 'admin_strong_pass'
    if (!isAdminOrHigher.value)
      (regexp = simpleUserStrongPassCheck), (errText = 'user_strong_pass')

    if (regexp.test(value)) {
      isPasswordError.value = false
      return callback()
    }

    isPasswordError.value = true
    callback(new Error($t(`settings.user.errors.${errText}`)))
  } else {
    if (simplePassCheck.test(value)) {
      isPasswordError.value = false

      return callback()
    }

    isPasswordError.value = true
    callback(new Error($t('settings.user.errors.simple_pass')))
  }
}

const validateRepeatPass = (rule, value, callback) => {
  console.log(value !== data.value.newPassword, 'sss')
  if (value === '') {
    callback(new Error($t('loginPass.errors.newPass')))
  } else if (value !== data.value.newPassword) {
    callback(new Error($t('loginPass.errors.notMatchPass')))
  } else {
    callback()
  }
}

const rules = ref({
  oldPassword: [{ required: true, message: $t('loginPass.errors.pass') }],
  newPassword: [
    {
      validator: validatePassword
    },
    { required: true, message: $t('loginPass.errors.pass') }
  ],
  newPasswordConfirm: [{}, { validator: validateRepeatPass, trigger: 'blur' }]
})

const changePasswordHandler = async () => {
  await refs.userChangePasswordValidation.value.validate(async (valid) => {
    if (!valid) return

    try {
      loading.value = true

      await changePassword({
        password: data.value.oldPassword,
        newPassword: data.value.newPassword
      })

      instance.proxy.$showSuccess($t('settings.user.success.change_password'))

      emits('onClose')
    } catch (err) {
      throw new Error(err, 'sssss')
    } finally {
      loading.value = false
    }
  })
}
</script>
