var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex w-full justify-between items-center"},[_c('div',{staticClass:"mr-4"},[_c('div',{staticClass:"flex items-center justify-center w-16 h-16 bg-lightGrey-100 rounded-full border border-lightGrey-200 cursor-pointer",on:{"click":function($event){return _vm.$refs.file.click()}}},[(_setup.avatarUrl)?_c('img',{staticClass:"w-full h-full rounded-full",attrs:{"alt":"avatar","src":_setup.avatarUrl}}):_c(_setup.NoAvatarIcon),_c('input',{ref:"file",staticClass:"none invisible",attrs:{"type":"file"},domProps:{"value":_setup.avatar},on:{"change":function($event){return _vm.$emit('uploadAvatar', $event)}}})],1)]),_c('div',{staticClass:"flex gap-3"},_vm._l((_setup.colors),function(color){return _c('div',{key:color.id,class:[
        'rounded-full',
        'cursor-pointer',
        'w-8',
        'h-8',
        'flex',
        'justify-center',
        'items-center',
        { 'border border-blue-200': color.background === _vm.selectedColor }
      ],style:('background-color:' + color.background),on:{"click":function($event){return _vm.$emit('setColor', color.background)}}},[(color.background === _vm.selectedColor)?_c(_setup.SelectedColor):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }