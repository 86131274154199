export default Object.freeze({
  NO_ACCESS: 'NO_ACCESS',         // 0
  OBSERVER: 'OBSERVER',           // 1
  READER: 'READER',               // 2
  OPERATOR: 'OPERATOR',           // 3
  EDITOR: 'EDITOR',               // 4
  ADMIN: 'ADMIN',                 // 5
  SUPERVISOR: 'SUPERVISOR',       // 6
  TARIFF_EDITOR: 'TARIFF_EDITOR', // 7
  CONTROLLER: 'CONTROLLER',       // 8
})
