<template>
  <div class="flex w-full justify-between items-center">
    <div class="mr-4">
      <div
        class="flex items-center justify-center w-16 h-16 bg-lightGrey-100 rounded-full border border-lightGrey-200 cursor-pointer"
        @click="$refs.file.click()"
      >
        <img
          v-if="avatarUrl"
          alt="avatar"
          class="w-full h-full rounded-full"
          :src="avatarUrl"
        />
        <NoAvatarIcon v-else />
        <input
          :value="avatar"
          ref="file"
          type="file"
          class="none invisible"
          @change="$emit('uploadAvatar', $event)"
        />
      </div>
    </div>
    <div class="flex gap-3">
      <div
        v-for="color in colors"
        :key="color.id"
        :style="'background-color:' + color.background"
        :class="[
          'rounded-full',
          'cursor-pointer',
          'w-8',
          'h-8',
          'flex',
          'justify-center',
          'items-center',
          { 'border border-blue-200': color.background === selectedColor }
        ]"
        @click="$emit('setColor', color.background)"
      >
        <SelectedColor v-if="color.background === selectedColor" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import { createVuexHelpers } from 'vue2-helpers'
const { useGetters } = createVuexHelpers()

import SelectedColor from '@/components/companyControlsCenter/assets/icons/check.vue'
import NoAvatarIcon from '@/components/companyControlsCenter/assets/icons/no-avatar.vue'
import Avatar from '@/components/companyControlsCenter/assets/images/Avatar.svg'

import allRoles from '@/enums/roles.js'

const { roles } = useGetters('login', ['roles'])

const avatar = ref(null)

const colors = [
  {
    id: 1,
    background: '#F2D6FF'
  },
  {
    id: 2,
    background: '#D6D6FF'
  },
  {
    id: 3,
    background: '#D6F5FF'
  },
  {
    id: 4,
    background: '#D6FFE6'
  },
  {
    id: 5,
    background: '#EDFFD6'
  },
  {
    id: 6,
    background: '#F6F7FA'
  }
]

const props = defineProps({
  selectedColor: {
    type: String,
    default: ''
  },
  photos: {
    type: Array,
    default: () => []
  }
})

const avatarUrl = computed(() => {
  if (roles.value.key === allRoles.READER) return
  const img = props.photos.find((i) => i.url.indexOf('110') > 1)
  return img && img.url ? '/' + 'api_v1' + img.url : Avatar
})
</script>
